import logo from "../../../assets/images/coewarri.png"
import { useState } from "react";

const NavHeader = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{backgroundColor:'#0C0F2E'}}>
      <div className="container-fluid">
        <a className="navbar-brand d-flex align-items-center justify-content-center" href="/">
          <img height={'30px'} src={logo} alt="dspg_logo" style={{backgroundColor:'#fff', padding:'3px', borderRadius:'20%', marginRight:'0.5rem'}}/>
          <h4 className='text-white font-weight-semibold'>COEWARRI</h4>
        </a>
        <button
          className="navbar-toggler dspg-dark"
          type="button"
          onClick={toggleNav} // Toggle the navigation menu
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link text-white active" aria-current="page" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="https://applications.mycoewarri.com/apply">Applications Portal</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="https://pg-applications.mycoewarri.com/apply">Postgraduate Applications</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="https://pgschool.mycoewarri.com">Postgraduate Student Portal</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="https://student.mycoewarri.com">Student Portal</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavHeader